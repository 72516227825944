import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["additionalFilters", "icon"];

  toggle() {
    this.additionalFiltersTargets.forEach((element) => {
      if (element.classList.contains('show')) {
        element.classList.remove('show');
        element.style.maxHeight = null; // Fecha a div imediatamente
      } else {
        element.classList.add('show');
        element.style.maxHeight = element.scrollHeight + "px"; // Abre a div com a altura necessária
      }
    });

    // Alterna a rotação do ícone
    this.iconTarget.classList.toggle("rotated");
  }
}
