import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "deactivationReason"]

  connect() {
    this.toggleDeactivationReason()
  }

  toggleDeactivationReason() {
    if (this.checkboxTarget.checked) {
      this.showDeactivationReason();
    } else {
      this.hideAndClearDeactivationReason();
    }
  }

  showDeactivationReason() {
    this.deactivationReasonTarget.style.display = 'block';
  }

  hideAndClearDeactivationReason() {
    this.deactivationReasonTarget.style.display = 'none';
    this.clearInputField();
  }

  clearInputField() {
    let inputField = this.deactivationReasonTarget.querySelector('input');
    if (inputField) {
      inputField.value = '';
    }
  }
  
  deleteFile(event) {
    event.preventDefault();
    const dataFieldId = $(event.currentTarget).data('fixed-asset-file-id');

    if (dataFieldId) {
      const destroyField = $(event.currentTarget).prev();
      destroyField[0].value = dataFieldId;
      $(event.currentTarget).closest('.card-file').hide();
    }
  }
}
