import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nestedForm", "buttonDeliveryAddress", "removeButton"];

  connect() {
  }

  show() {
    this.nestedFormTarget.classList.remove('d-none');
    this.buttonDeliveryAddressTarget.classList.add('d-none');
    this.removeButtonTarget.classList.remove('d-none');
  }

  hide() {
    this.nestedFormTarget.classList.add('d-none');
    this.buttonDeliveryAddressTarget.classList.remove('d-none');
    this.removeButtonTarget.classList.add('d-none');

    let formInputs = this.nestedFormTarget.querySelectorAll('input');
    formInputs.forEach(input => {
      input.value = '';
    });
  }
}
