import { Controller } from "@hotwired/stimulus"
import 'selectize';

export default class BaseController extends Controller {
    connect() {
        $(this.element).closest('select.selectized').on('change', (event) => {
            let action = $(event.currentTarget).data('action')
            if (action) {
                let method = action.substring(action.indexOf('#') + 1)
                if (typeof this[method] === 'function') {
                    this[method](event)
                }
            }
        })
    }
}
