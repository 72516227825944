import { Controller } from "@hotwired/stimulus";
import jquery from 'jquery'

// Connects to data-controller="participations"
export default class extends Controller {
  static targets = ["participations", "form"];

  connect() {
  };

  addOne(event) {
    const participated = jquery('#participations-data').data('source');

    event.preventDefault();

    fetch(`/participations/add_one/${participated.id}`, {
      method: "PUT",
      body: new FormData(this.formTarget)
    }).then(response => response.json())
    .then((data) => {
      this.formTarget.outerHTML = data.form;
      window.scrollTo(0, document.body.scrollHeight);
    })
  };

  submit(e) {
    e.preventDefault();

    fetch(this.formTarget.action, {
      method: "POST",
      body: new FormData(this.formTarget)
    }).then(response => response.json())
    .then((data) => {
      this.formTarget.outerHTML = data.form;
      window.scrollTo(0, document.body.scrollHeight);
      location.reload();
    })
  };
}
