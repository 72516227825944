import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3";

// Connects to data-controller="network"
export default class extends Controller {

  connect() {
         
    var width = 800,
    height = 800;

  var data = {
    "myDIVs": [{
      "name": "div1"
    }, {
      "name": "div2"
    }, {
      "name": "div3"
    }, {
      "name": "div4"
    }],
    "myLinks": [{
      "source": 0,
      "target": 1
    }, {
      "source": 1,
      "target": 2
    }, {
      "source": 2,
      "target": 3
    }]
  };

  var nodes = data.myDIVs,
    links = data.myLinks;

  var svg = d3.select('body').append('svg')
    .attr('width', width)
    .attr('height', height);

  var force = d3.layout.force()
    .size([width, height])
    .nodes(nodes)
    .links(links)
    .linkDistance(250)

  var link = svg.selectAll('.link')
    .data(links)
    .enter().append('line')
    .attr('class', 'link');

  var node = d3.selectAll('.node')
    .data(nodes)
    .each(function(d) {
      var self = d3.select('#' + d.name);
      self.style("position", "absolute");
    });

  force.on('end', function() {
    node
      .style('left', function(d) {
        return d.x + "px";
      })
      .style('top', function(d) {
        const navbarH = document.getElementsByClassName("navbar")[0].clientHeight

        return d.y + navbarH + "px";
      });

    link.attr('x1', function(d) {
        return d.source.x;
      })
      .attr('y1', function(d) {
        return d.source.y;
      })
      .attr('x2', function(d) {
        return d.target.x;
      })
      .attr('y2', function(d) {
        return d.target.y;
      });
  });
  
  force.start();
  for (var i = 0; i < 100; ++i) force.tick();
  force.stop();
  };

}