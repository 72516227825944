import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  filter(e) {
    Turbo.visit(`properties?company_id=${$(this.element).find('#company_id')[0].value}`)
  }
}
