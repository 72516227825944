import { Controller } from "@hotwired/stimulus";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import customIconUrl from '../../assets/images/villas_logo.png';

export default class extends Controller {
  static values = {
    propertiesCoordinates: Array,
  };

  connect() {
    this.initializeMap();
    this.addMarkersToMap(this.propertiesCoordinatesValue);
  }

  initializeMap() {
    const defaultLatLng = [0, 0];
    const defaultZoom = 11;

    this.map = L.map('map').setView(defaultLatLng, defaultZoom);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
  }

  addMarkersToMap(properties) {
    if (!properties || properties.length === 0) return;


    const customIcon = L.icon({
      iconUrl: customIconUrl,
      iconSize: [50, 60],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34]
    });

    const bounds = new L.LatLngBounds();

    properties.forEach(property => {
      if (property.latitude && property.longitude) {
        const latLng = [property.latitude, property.longitude];
        L.marker(latLng, { icon: customIcon }).addTo(this.map)
          .bindPopup(`${property.name}<br>${property.count} propriedades`);

        bounds.extend(latLng);
      }
    });

    if (bounds.isValid()) {
      this.map.fitBounds(bounds);
    }
  }
}
