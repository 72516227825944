import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
  }

  closeModal() {
    $('body').css({
      'overflow': 'auto',
      'padding-right': 'initial'
    });
    $('.modal-open').removeClass('modal-open');
    $('.modal-backdrop').remove();

    $('.spinner-container').hide();
  }

  deleteFile(event) {
    event.preventDefault();
    const dataFieldId = $(event.currentTarget).data('site-diary-task-file-id');

    if (dataFieldId) {
      const destroyField = $(event.currentTarget).prev();
      destroyField[0].value = dataFieldId;
      $(event.currentTarget).closest('.card-file').hide();
    }
  }

  toggleStandaloneTask(event) {
    var checkbox = event.currentTarget.querySelector('input[type="checkbox"]');
    var standaloneTask = event.currentTarget.parentNode.parentNode.querySelector('.standalone-task');
    var siteTaskSelector = event.currentTarget.parentNode.parentNode.querySelector('.site-task-selector');

    if (checkbox.checked) {
      siteTaskSelector.style.display = 'none';
      standaloneTask.style.display = 'block';
    } else {
      siteTaskSelector.style.display = 'block';
      standaloneTask.style.display = 'none';
    }
  }
}
