import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }

  redirectToWhatsapp(event) {
    const id = window.location.pathname.split('/')[2];
    const url = event.currentTarget.dataset.url

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': this.getMetaValue('csrf-token')
      },
      body: new URLSearchParams({
        quote_id: id
      })
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        console.log(response);
      }
    }).then((data) => {
      window.open(data.link, '_blank');
      location.reload();
    })
  }
}
