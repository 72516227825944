import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar-height"
export default class extends Controller {
  static targets = ["menu"]

  connect() {
    this.adjustNavbarHeight();
    window.addEventListener('resize', this.adjustNavbarHeight.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.adjustNavbarHeight.bind(this));
  }

  adjustNavbarHeight() {
    const mainView = document.querySelector('.main-view');
    const mainViewHeight = mainView ? mainView.scrollHeight : 0;
    const viewportHeight = window.innerHeight;

    if (mainViewHeight > viewportHeight) {
      this.menuTarget.style.height = `${mainViewHeight}px`;
    } else {
      this.menuTarget.style.height = `100vh`;
    }
  }
}
