import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-component"
export default class extends Controller {
  static targets = ["selectAll", "rowCheckbox"];

  connect() {
    if (this.hasSelectAllTarget) {
      this.selectAllTarget.addEventListener('change', this.toggleAll.bind(this));
    }
  }

  toggleAll() {
    const isChecked = this.selectAllTarget.checked;
    this.rowCheckboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked;
    });
  }
}
