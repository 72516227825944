import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["shared", "new"];

  toggleCorporateCardLimit(event) {
    event.preventDefault();
    const dependencyChecked = event.currentTarget.value === "true";
    this.sharedTarget.classList.toggle("d-none", !dependencyChecked);
    this.newTarget.classList.toggle("d-none", dependencyChecked);
    $(this.sharedTarget).find('input').val("")
    $(this.newTarget).find('input').val("")
  }
}
