import { Controller } from "@hotwired/stimulus"
import {GoogleCharts, } from 'google-charts';
import jquery from 'jquery'

// Connects to data-controller="organigram"
export default class extends Controller {
  static targets = ["graph"];

  connect() {
    const organizations = jquery('#organigram-data').data('source');

    GoogleCharts.load(drawOrgChart, {packages:["orgchart"]});

    function drawOrgChart() {
      var data = new GoogleCharts.api.visualization.DataTable();
      data.addColumn('string', 'participated');
      data.addColumn('string', 'participant');
      data.addColumn('string', 'tooltip');

      // For each orgchart box, provide the name, manager, and tooltip to show.
      data.addRows(organizations);

      // Create the chart.
      var chart = new GoogleCharts.api.visualization.OrgChart(document.getElementById('chart_div'));
      // Draw the chart, setting the allowHtml option to true for the tooltips.
      chart.draw(data, {'allowHtml':true});
    }
  };

}