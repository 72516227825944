import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="users"
export default class extends Controller {
  connect() {
  }

  changeActive(event) {
    const userId = event.target.dataset.userId;
    const url = `/users/${userId}`;

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        user: {
          active: event.target.checked
        }
      })
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  changeVettingPurchaseRequest(event) {
    const userId = event.target.dataset.userId;
    const url = `/users/${userId}/update_vetting_pr`;

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        user: {
          settings: {
            authorizations: {
              purchase_requests: {
                vetting: event.target.checked
              }
            }
          }
        }
      })
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
}
