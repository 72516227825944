// @ts-check

/**
 * Flash module
 * @module flash
 */

/**
 * Raise a flash message.
 * @function
 * @name flash
 * @param {('notice'|'alert')} type - Type can be an alert or notice.
 * @param {string} message - The message to be displayed.
 */
export default function flash(type, message) {
    switch (type) {
      case 'notice':
        $('body').append(`
          <div class="alert alert-info alert-dismissible fade show m-1" role="alert">
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            ${message}
          </div>
        `);
      case 'alert':
        $('body').append(`
          <div class="alert alert-warning alert-dismissible fade show m-1" role="alert">
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            ${message}
          </div>
        `);
    }
}