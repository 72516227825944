import { Controller } from "@hotwired/stimulus"
import flash from "../helpers/flash";

export default class extends Controller {
  static targets = ["checkbox", "button", "select", "resourceCard"];

  connect() {
  }

  goToResources() {
    const selectedIds = this.checkboxTargets
    .filter((checkbox) => checkbox.checked)
    .map((checkbox) => checkbox.value);

    const queryParams = selectedIds.map(id => `purchase_request_ids[]=${id}`).join('&');

    const url = `new/select_resources?${queryParams}`;

    // Redirecting to the new URL
    window.location.href = url;
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }

  createQuote(event) {
    event.preventDefault();

    const supplierId = this.selectTarget.value;
    const url = event.currentTarget.dataset.url + "?supplier_id=" + supplierId;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': this.getMetaValue('csrf-token')
      },
      body: new URLSearchParams({
        supplier_id: supplierId
      })
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        console.log(response);
      }
    }).then((data) => {
      window.open(data.link, '_blank');
      flash('notice', data.message)
      location.reload();
    })
  }
}
