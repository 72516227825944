import { Controller } from "@hotwired/stimulus"
import { GoogleCharts } from 'google-charts';


export default class extends Controller {
  static values = {
    columnChartData: Array,
    chartname: String,
    histogramChartData: Array,
    superHistogramChartData: Array,
    histogramtitle: String,
    superhistogramtitle: String,
    punchesChartData: Array,
    punchesPieChartData: Array,
    chartTitle: String,
  };

  connect() {
    GoogleCharts.load('current', {
      'packages':['geochart'],
    });

    switch (this.chartnameValue) {
      case "collumnchart":
        GoogleCharts.load(this.drawCollumnChart.bind(this));
        break;
      case "histogram":
        GoogleCharts.load(this.drawHistogram.bind(this));
        break;
      case "superhistogram":
        GoogleCharts.load(this.drawSuperHistogram.bind(this));
        break;
      case "punchescolumnchart":
        GoogleCharts.load(this.drawPunchesCollumnChart.bind(this));
        break;
      case "punchespiechart":
        GoogleCharts.load(this.drawPunchesPieChart.bind(this));
        break;
    }
  }

  drawHistogram() {
    var histogramData = this.histogramChartDataValue;
    const propertyIds = [];

    const data = new google.visualization.DataTable();
    data.addColumn('string', 'Propriedades');
    data.addColumn('number', 'Etapa');
    data.addColumn({'type': 'string', 'role': 'tooltip', 'p': {'html': true}});

    _.each(histogramData, function (row, index) {
      data.addRow([row[1], row[2], row[3]]);
      propertyIds[index] = row[0];
    });

    var options = {
      title: 'Propriedade por estado',
      tooltip: {isHtml: true},
      legend: { position: 'none' },
      height: 500,
      // hAxis: {
      //   ticks: Array.from({length: 15}, (_, i) => i + 1)
      // },
      hAxis: {
        ticks: [
          {v: 1, f: 'Desmenbrando'},
          {v: 2, f: 'Desmenbrado'},
          {v: 3, f: 'Alvará req.'},
          {v: 4, f: 'Alvará obtido'},
          {v: 5, f: 'Hab. req.'},
          {v: 6, f: 'Hab. obtido'},
          {v: 7, f: 'Averbação req.'},
          {v: 8, f: 'Averbada'},
          {v: 9, f: 'Envio doc. cliente'},
          {v: 10, f: 'Doc. cliente OK'},
          {v: 11, f: 'ITBI req.'},
          {v: 12, f: 'ITBI pago'},
          {v: 13, f: 'Contrato assinado'},
          {v: 14, f: 'Registrado'},
          {v: 15, f: 'Pagamento OK'},
        ],
      },
      bar: { groupWidth: '100%' }
    };

    var chart = new google.visualization.Histogram(this.element);
    chart.draw(data, options);

    google.visualization.events.addListener(chart, 'select', () => {
      const selectedItem = chart.getSelection()[0];
      if (selectedItem) {
        const propertyIdentifier = propertyIds[selectedItem.row];
        if (propertyIdentifier) {
          window.location.href = `/properties/${propertyIdentifier}`;
        }
      }
    });
  }


  drawSuperHistogram() {
    const superHistogramData = this.superHistogramChartDataValue;
    const propertyIds = [];

    const data = new google.visualization.DataTable();
    data.addColumn('string', 'Propriedades');
    data.addColumn('number', 'Etapa');
    data.addColumn({'type': 'string', 'role': 'tooltip', 'p': {'html': true}});

    _.each(superHistogramData, function (row, index) {
      data.addRow([row[1], row[2], row[3]]);
      propertyIds[index] = row[0];
    });

    var options = {
      title: 'Histograma Propriedades por super estado',
      tooltip: {isHtml: true},
      legend: { position: 'none' },
      height: 500,
      hAxis: {
        title: 'Super etapas',
        ticks: [
          {v: 1, f: 'Pré const.'},
          {v: 2, f: 'Em const.'},
          {v: 3, f: 'Á vender'},
          {v: 4, f: 'Vendido'},
          {v: 5, f: 'Em caixa'},
        ],
      },
      animation:{
        duration: 1000,
        easing: 'out',
      },
    };

    var chart = new google.visualization.Histogram(this.element);
    chart.draw(data, options);

    google.visualization.events.addListener(chart, 'select', () => {
      const selectedItem = chart.getSelection()[0];
      if (selectedItem) {
        const propertyIdentifier = propertyIds[selectedItem.row];
        if (propertyIdentifier) {
          window.location.href = `/properties/${propertyIdentifier}`;
        }
      }
    });
  }

  drawPunchesCollumnChart() {
    const data = google.visualization.arrayToDataTable(this.punchesChartDataValue);

    const options = {
      title: this.chartTitleValue,
      width: '100%',
      height: '100%',
      legend: { position: 'top', maxLines: 3 },
      bar: { groupWidth: '75%' },
      isStacked: true,
      colors: ['#135b9d', '#42817c', '#71a75b', '#a1ce3b', '#c0de27', '#dfee13', '#ffff00', '#ffe100', '#ffc300', '#ffa500', '#ff8f00', '#ff7412']
    };

    const chart = new google.visualization.ColumnChart(this.element);
    chart.draw(data, options);
  }  

  drawPunchesPieChart() {
    const data = google.visualization.arrayToDataTable(this.punchesPieChartDataValue);

    const options = {
      title: this.chartTitleValue,
      width: '100%',
      height: '100%',
      is3D: true,
      pieSliceText: 'value',
      colors: ['#135b9d', '#42817c', '#71a75b', '#a1ce3b', '#c0de27', '#dfee13', '#ffe100', '#ffc300', '#ffa500', '#ff8f00', '#ff7412']
    };

    const chart = new google.visualization.PieChart(this.element);
    chart.draw(data, options);
  }

  drawCollumnChart() {
    const data = new google.visualization.DataTable();

    data.addColumn('string', 'Propriedades');
    data.addColumn('number', 'Antes da construção');
    data.addColumn('number', 'Em construção');
    data.addColumn('number', 'Vendas');
    data.addColumn('number', 'Meta de construção');
    data.addColumn('number', 'Meta de vendas');

    var properties = this.columnChartDataValue;

    var min, max;

    _.each(properties, function(property) {
      const weeks = 52;

      const registrationDate = new Date(property.registration_date);
      const startedBuildingDate = property.started_building_at ? new Date(property.started_building_at) : 0 ;
      const finishedBuildingDate = property.ended_building_at ? new Date(property.ended_building_at) : 0;
      const soldDate = property.sold_at ? new Date(property.sold_at) : 0 ;
      const currentDate = new Date();


      let weeksBeforeStart = 0;
        if (startedBuildingDate) {
          weeksBeforeStart = -Math.floor((startedBuildingDate - registrationDate) / (7 * 24 * 60 * 60 * 1000));
        } else {
          weeksBeforeStart = -Math.floor((currentDate - registrationDate) / (7 * 24 * 60 * 60 * 1000));
        }

      let weeksBuilding = 0;
      if (finishedBuildingDate != 0) {
        weeksBuilding = Math.floor((finishedBuildingDate - startedBuildingDate) / (7 * 24 * 60 * 60 * 1000));
      } else if (startedBuildingDate != 0) {
        weeksBuilding = Math.floor((currentDate - startedBuildingDate) / (7 * 24 * 60 * 60 * 1000));
      }

      const weeksSelling = Math.max(0, Math.floor((soldDate - finishedBuildingDate) / (7 * 24 * 60 * 60 * 1000)));

      max = _.max([max, weeksSelling + weeksBuilding]);
      min = _.max([-1 * max, _.min([min, weeksBeforeStart])]);

      data.addRows([
        [property.name, weeksBeforeStart, weeksBuilding, weeksSelling, 12, 24]
      ]);
    });

    const options = {
      title: 'Progresso das Propriedades',
      hAxis: {
        title: 'Propriedades',
        textPosition: 'none'
      },
      vAxis: {
        title: 'Semanas decorridas',
        viewWindow: {
          min: min,
          max: max
        }
      },
      isStacked: true,
      legend: 'none',
      height: 600,
      trendlines: {
        0: {
          color: 'gray',
          lineWidth: 2,
          opacity: 0.5,
          showR2: false,
          visibleInLegend: false,
          type: 'linear',
          pointsVisible: false,
          tooltip: false,
        }
      },
      series: {
        0: { color: '#F66B27'},
        1: { color: '#E1EA45' },
        2: { color: '#36BCBA' },
        3: {type: 'line', color: '#E1EA45'},
        4: {type: 'line', color: '#36BCBA'},
      }
    };

    const chart = new google.visualization.ColumnChart(this.element);
    chart.draw(data, options);

    // Make each column clickable
    google.visualization.events.addListener(chart, 'select', () => {
      const selectedItem = chart.getSelection()[0];
      if (selectedItem) {
        const property = properties[selectedItem.row];
        if (property) {
          window.location.href = `/properties/${property.id}`;
        }
      }
    });
  }
}
