import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "comments", "quantity"]
  static values = { requestedQuantity: Number }

  connect() {
    this.updateClasses();
  }

  toggleQuantity(event) {
    const isChecked = event.target.checked;
    const input = this.quantityTarget;

    if (isChecked) {
      this.quantityTarget.value = this.requestedQuantityValue;
      this.quantityTarget.classList.add("received-quantity-ok");
      this.updateClasses();
    } else {
      this.quantityTarget.disabled = false;
      this.quantityTarget.classList.remove("received-quantity-ok");
    }
  }

  updateClasses() {
    this.toggleTarget.checked = false;
    this.toggleComments('show')

    if (this.quantityTarget.value == 0) {
      this.quantityTarget.classList.add("not-received-quantity");
      this.quantityTarget.classList.remove("partially-received-quantity");
      this.quantityTarget.classList.remove("received-quantity-ok");
    } else if (this.quantityTarget.value < this.requestedQuantityValue) {
      this.quantityTarget.classList.add("partially-received-quantity");
      this.quantityTarget.classList.remove("not-received-quantity");
      this.quantityTarget.classList.remove("received-quantity-ok");
    } else {
      this.quantityTarget.classList.add("received-quantity-ok");
      this.quantityTarget.classList.remove("not-received-quantity");
      this.quantityTarget.classList.remove("partially-received-quantity");
      this.commentsTarget.querySelector("textarea").value = "";
      this.toggleComments('hide')
      this.toggleTarget.checked = true;
    }
  }

  toggleComments(event) {
    if (event == 'show') {
      this.commentsTarget.style.display = "block";
    } else {
      this.commentsTarget.style.display = "none";
    }
  }

  uncheckCheckbox() {
    console.log("uncheckCheckbox")
    const toggle = this.toggleTarget;
    toggle.checked = false;
  }
}
