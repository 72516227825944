import Swal from 'sweetalert2';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  showConfirmation(event) {
    event.preventDefault();

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success m-2",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, pode excluir!",
      cancelButtonText: "Cancelar!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const form = event.target.closest('form');
        form.submit();
      }
    }).catch(error => {
      console.error('Erro ao excluir registro', error );
      swalWithBootstrapButtons.fire({
        title: "Erro",
        text: "Ocorreu um erro ao excluir o registro. Fale com sua equipe de TI.",
        icon: "error"
      });
    });
  }
}
