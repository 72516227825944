import { Controller } from "@hotwired/stimulus"
import _ from "lodash";
import 'selectize';

export default class extends Controller {
  static targets = [
    "resourceSelectors",
    'addNoteButton',
    'saveNoteButton',
    'note',
    'filterButton',
    'selectAll',
    'rowCheckbox',
  ];

  static values = {
    fieldId: String,
  };

  connect() {
    this.bindResourceChosen();
    this.selectAllTarget.addEventListener('change', this.toggleAll.bind(this));
  }

  bindResourceChosen() {
    $(this.element).find('#pr-resource-id').on('change', (event) => {
      this.resourceChosen(event);
    });
  }

  toggleAll() {
    const isChecked = this.selectAllTarget.checked;
    this.rowCheckboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked;
    });
  }

  filter() {
    let selectBox = $(this.element).find('#building_ids');
    let baseUrl = '/purchase_requests';
    let params = new URLSearchParams(window.location.search);

    if (selectBox.val().length > 0) {
      params.set('building_ids', selectBox.val());
    }

    Turbo.visit(`${baseUrl}?${params.toString()}`);
  }

  resourceChosen(event) {
    event.preventDefault();

    // Get the selected resource ID
    const prResourceId = $(event.currentTarget).find('option')[0].value;

    // Find pr_resource details wrapper
    const resourceDetailsWrapper = $(event.currentTarget).closest(".card-resource-info").find(".resource-detail-association");

    // Find pr_resource units wrapper
    const unitsWrapper = $(event.currentTarget).closest(".card-resource-info").find(".resource-units-association");

    // Find the associated pr_resource detail dropdown
    const resourceDetailsSelectize = resourceDetailsWrapper.find("select.chosen")[0].selectize

    // Find the associated pr_resource unitofmeasure dropdown
    const unitsSelectize = unitsWrapper.find("select.chosen")[0].selectize

    // Fetch the resource details for the selected resource
    fetch(`/resource_details?resource_id=${prResourceId}`)
      .then((response) => response.json())
      .then((resourceDetails) => {
        // Clear the resource_detail dropdown options
        resourceDetailsSelectize.clear();
        resourceDetailsSelectize.clearOptions();

        if ($.isEmptyObject(resourceDetails)) {
          resourceDetailsWrapper.hide()
        } else {
          // Add the new options based on the fetched resource details
          _.each(resourceDetails, ({id, description}) => resourceDetailsSelectize.addOption({ text: description, value: id }))
          resourceDetailsWrapper.show()
        }
      })
      .catch((error) => {
        console.error(error);
        $resourceDetailsWrapper.hide();
      });

      fetch(`/unit_of_measures.json?resource_id=${prResourceId}`, {
        headers: {
          'Accept': 'application/json' // Specify that you want to accept JSON
        }
      })
        .then((response) => response.json())
        .then((unitOfMeasures) => {
          // Clear the unit_of_measure dropdown options if you have one
          unitsSelectize.clear();
          unitsSelectize.clearOptions();

          // Add the new options based on the fetched unit of measures
          _.each(unitOfMeasures, ({id, description, symbol, is_default}) => {
            unitsSelectize.addOption({ text: `${description} (${symbol})`, value: id })
            if (is_default) {
              unitsSelectize.addItem(id)
            }
          });

          // Show the wrapper containing the unit of measures
          unitsWrapper.show();
        })
        .catch((error) => {
          console.error(error);
          $unitsWrapper.hide(); // Hide the wrapper if an error occurs
        });
  }

  addResource(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const purpose = button.dataset.purpose;
    fetch(`/purchase_requests/new_purchase_request_resource_fields?purpose=${purpose}`)
      .then((response) => response.text())
      .then((html) => {
        const template = document.createElement('template');
        template.innerHTML = html.trim();
        const row = template.content.querySelector('.card-resource');

        this.resourceSelectorsTarget.appendChild(row);
        $(this.resourceSelectorsTarget).children().last().find('.chosen').selectize({
          width: '100%'
        });
        $(this.resourceSelectorsTarget).children().last().find('.row').last().find('.chosen').first().on('change', (event) => {
          this.resourceChosen(event);
        });
    });
  }

  deleteResource(event) {
    event.preventDefault();
    const dataFieldId = $(event.currentTarget).data('purchase-request-resource-id');
    if (dataFieldId) {
      const destroyField = $(event.currentTarget).prev();
      destroyField[0].value = "true";
      // Hide the removed field from UI.
      $(event.currentTarget).closest('.purchase-request-resource').hide();
    } else {
      $(event.currentTarget).closest('.card-resource').remove();
    }
  }


  addNote(e) {
    e.preventDefault();
    $(this.addNoteButtonTarget).hide()
    $(this.saveNoteButtonTarget).removeClass("d-none")
    $(this.noteTarget).show()
  }

  submitNote(e) {
    e.preventDefault();
    const form = this.noteTarget.querySelector('form.simple_form');
    form.submit()
  }
}
