import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["worker", "contractor", "enterprise", "jobrole"]

  connect() {
    this.updateDateOut()
  }

  updateDateOut() {
    const dateOutDiv = document.getElementById('dateOut');
    const checkboxElement = document.getElementById('disableDateOutCheckbox');

    checkboxElement.addEventListener('change', function() {
      const selects = dateOutDiv.getElementsByTagName('select');
      for(let i = 0; i < selects.length; i++) {
        selects[i].disabled = this.checked;
        if (this.checked) {
          selects[i].value = "";
        }
      }
    });
  }

  updateWorkerInfo() {
    const workerId = this.workerTarget.value
    if (workerId) {
      fetch(`/accesses/workers/${workerId}/info`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => this.handleSuccess(data))
        .catch(error => this.handleError(error));
    }
  }

  handleSuccess(data) {
    this.contractorTarget.value = data.contractor_id;
    this.enterpriseTarget.value = data.enterprise_id;
    this.jobroleTarget.value = data.job_role_id;
  }

  handleError(error) {
    console.log(error)
  }
}
