import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accident", "urgency", "victims", "aid"]

  connect() {
    this.adjustFieldsBasedOnAccidentValue();
  }

  toggleHazardAccident() {
    this.adjustFieldsBasedOnAccidentValue();
  }

  adjustFieldsBasedOnAccidentValue() {
    const checkedInput = this.accidentTarget.querySelector('input:checked');

    const accidentValue = checkedInput?.value;
    const displayValue = accidentValue === "true" ? "block" : "none";

    ["urgency", "victims", "aid"].forEach(targetName => {
      this[`${targetName}Target`].style.display = displayValue;


      if (displayValue === "none") {
        $(this[`${targetName}Target`]).find('input[type="radio"]').prop('checked', false)
      }
    });
  }

  deleteFile(event) {
    event.preventDefault();
    const dataFieldId = $(event.currentTarget).data('hazard-report-file-id');

    if (dataFieldId) {
      const destroyField = $(event.currentTarget).prev();
      destroyField[0].value = dataFieldId;
      $(event.currentTarget).closest('.card-file').hide();
    }
  }
}
