import { Controller } from "@hotwired/stimulus"

/**
 * Flash module
 * @module PropertyController
 */
export default class extends Controller {
  /**
   * Controller static targets
   * @constant
   * @name propertyTargets
   */
  static targets = [ "registry_office" ]

  /**
   * In the initializer of property controller we listen to changes in the registry_office {@link targets}
   * so we run {@link registryOfficeValueChanged}
   * @function
   */
  connect() {
    this.registry_officeTarget.addEventListener('change', () => this.registryOfficeValueChanged())
  }

  registryOfficeValueChanged() {
    if (this.registry_officeTarget.value === 'new') {
      this.newRegistryOfficeSelected()
    } else {
      this.existingRegistryOfficeSelected()
    }
  }

  /**
   *
   */
  newRegistryOfficeSelected() {
    var newRegistryOfficeFormUrl = this.registry_officeTarget.dataset.newRegistryOfficeFormUrl;
    $.get(newRegistryOfficeFormUrl, function(data) {
      $('#new-registry-office-form').html(data);
      $('#new-registry-office-form').slideDown('fast');
    });
  }

  existingRegistryOfficeSelected() {
    $('#new-registry-office-form').slideUp('fast');
  }

  filter(e) {
    Turbo.visit(`properties?company_id=${$(this.element).find('#company_id')[0].value}`)
  }
}
