import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="company"
export default class extends Controller {
  static targets = ["participations"]
  
  connect() {
  }

  editParticipations(e) {
    e.preventDefault();
    fetch(e.currentTarget.href)
      .then(response => response.json())
      .then((data) => {
        this.participationsTarget.outerHTML = data.form
      })
  };
}
