import flash from "../helpers/flash";

export default function checkPlaceOfInterest(enterpriseId, element, successCallback = () => {}) {
  successCallback();
    // fetch(`/enterprises/${enterpriseId}/has_place_of_interest`)
    //   .then(response => response.json())
    //   .then(data => {
    //     if (data.has_place_of_interest) {
    //       $(element).find('#places-of-interest-selector').hide();
    //       $(element).find('#places-of-interest-selector').val("");
    //       $(element).find('#workplaces-selector').hide()
    //       $(element).find('#workplaces-selector').val("")
    //       successCallback();
    //     } else {
    //       flash("warning", "Esta fase ainda não está associada a uma localização.");
    //       $(element).find('#places-of-interest-selector').show();
    //       flash("warning", "Esta fase ainda não está associada a uma obra.")
    //       $(element).find('#workplaces-selector').show()
    //     }
    //   }
    // );
}