// Entry point for the build script in your package.json
// @ts-check

import "@hotwired/turbo-rails"
import "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
import 'selectize';
import _ from 'lodash';
import "trix"
import "@rails/actiontext"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// direct_uploads.js

addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})


window.bootstrap = bootstrap
window.jQuery = $
window.$ = $

import "./controllers"

const runOnLoad = (event) => {
  const target = event.target
  var modalTriggers = target.querySelectorAll("[data-toggle='modal']");

  if (modalTriggers) {
    modalTriggers.forEach(function (modalTrigger) {
      modalTrigger.addEventListener("click", function (event) {
        event.preventDefault();
        var modalTarget = event.target.getAttribute("data-target");
        var modal = new bootstrap.Modal(document.querySelector(modalTarget));
        modal.show();
      });
    });
  }

  // Selectize
  $(target).find('.chosen').selectize({
    plugins: ['remove_button'],
  });
}

$(document).on('turbo:load', function (event) {
  runOnLoad(event);
});

$(document).on('turbo:frame-load', function (event) {
  runOnLoad(event);
});

// Define a function to initialize the tooltips
function initializeTooltips() {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.forEach(function(tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
}

// Initialize tooltips on load
$(document).on('turbo:load', initializeTooltips);
