import { Turbo } from "@hotwired/turbo-rails";
import checkPlaceOfInterest from "../helpers/checkPlaceOfInterest";
import BaseController from "./controller";

export default class extends BaseController {
  connect() {
    super.connect()
  }

  constructionReportSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);

    fetch(e.target.action, {
      method: 'POST',
      body: formData
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = 'report.xlsx'; // Set desired filename
      downloadLink.click();
    })
    .catch(error => console.error('Error:', error));
  }

  choosingNewAccessType(event) {
    const workerFields = $(this.element).closest('form').find('.worker-field')
    if (event.target.value == 'Worker') {
      workerFields.css('display', 'block')
      workerFields.find('select').attr('required', true);
    } else {
      workerFields.css('display', 'none')
      workerFields.find('select').removeAttr('required');
    }
  }

  choosingNewAccessEnterprise () {
    const form = $(this.element).closest('form');
    const enterpriseId = form.find('#worker_enterprise_ids').val();
    checkPlaceOfInterest(enterpriseId, form)
  }
}
