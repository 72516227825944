import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "cnpj", "email", "name", "tradeName", "zipCode", "state", "cityName", "neighborhood", "streetName", "number", "complement" ]

  connect() {
  }

  getCnpjValue() {
    let cnpjValue = this.cnpjTarget.value;
    if (cnpjValue.length >= 14) {
      this.callApi(cnpjValue);
    }
  }

  callApi(cnpj) {
    fetch(`/suppliers/get_cnpj_data?cnpj=${cnpj}`)
      .then(response => response.json())
      .then(data => {
        this.updateFormFields(data);
      })
      .catch(error => console.error('Error:', error));
  }

  updateFormFields(data) {
    const fieldMap = {
      email: 'estabelecimento.email',
      name: 'razao_social',
      tradeName: 'estabelecimento.nome_fantasia',
      zipCode: 'estabelecimento.cep',
      state: 'estabelecimento.estado.nome',
      cityName: 'estabelecimento.cidade.nome',
      neighborhood: 'estabelecimento.bairro',
      streetName: 'estabelecimento.logradouro',
      number: 'estabelecimento.numero',
      complement: 'estabelecimento.complemento'
    };

    for (const [field, path] of Object.entries(fieldMap)) {
      const value = this.getValueFromPath(data, path);
      this[`${field}Target`].value = value || '';
    }
  }

  getValueFromPath(object, path) {
    return path.split('.').reduce((obj, key) => obj && obj[key], object);
  }
}