import { Controller } from "@hotwired/stimulus"
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
export default class extends Controller {
  static targets = [
    "radius",
    "radiusValue",
    "map",
    "setGeolocation",
  ]

  connect() {
    this.geolocation()
  }

  async geolocation() {
    try {
      const enterpriseRadius = this.element.dataset.radius ? this.element.dataset.radius : 150
      const token = this.element.getAttribute('data-token')
      mapboxgl.accessToken = token

      const enterpriseGeolocation = this.element.dataset.geolocation ? JSON.parse(this.element.dataset.geolocation) : null

      this.map = new mapboxgl.Map({
        container: this.mapTarget,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: enterpriseGeolocation || [-38.5434, -3.71839],
        zoom: enterpriseGeolocation ? 15 : 10
      })

      let lastMarker = null

      this.map.on('style.load', () => {
        if (enterpriseGeolocation) {
          const marker = new mapboxgl.Marker()
            .setLngLat([enterpriseGeolocation[0], enterpriseGeolocation[1]])
            .addTo(this.map)

          lastMarker = marker

          let center = turf.point([enterpriseGeolocation[0], enterpriseGeolocation[1]])
          let radius = enterpriseRadius

          let circle = turf.circle(center, radius, { units: 'meters' })

          this.addCircleLayer(circle)
        }
      })

      this.map.on('click', (e) => {
        const { lng, lat } = e.lngLat
        if (lastMarker) {
          lastMarker.remove()
        }

        this.removeCircleLayer()

        const marker = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(this.map)

        let center = turf.point([lng, lat])
        let radius = this.radiusTarget.value

        let circle = turf.circle(center, radius, { units: 'meters' })

        this.addCircleLayer(circle)
        lastMarker = marker
      })

      this.radiusTarget.addEventListener("input", () => {
        this.radiusValueTarget.textContent = this.radiusTarget.value

        this.removeCircleLayer()

        let center = turf.point([lastMarker.getLngLat().lng, lastMarker.getLngLat().lat])
        let radius = this.radiusTarget.value

        let circle = turf.circle(center, radius, { units: 'meters' })

        this.addCircleLayer(circle)
      })

      this.setGeolocationTarget.addEventListener('click', () => {
        fetch(`/enterprises/${this.element.dataset.enterpriseId}/set_geolocation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({
            latitude: lastMarker.getLngLat().lat,
            longitude: lastMarker.getLngLat().lng,
            radius: this.radiusTarget.value
          })
        }).then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          console.log('Geolocation set successfully!')

          window.location.href = `/enterprises/${this.element.dataset.enterpriseId}`
        }).catch(error => {
          console.error('Error setting geolocation:', error)
        })
      })
    } catch (error) {
      console.error('Error getting geolocation:', error)
    }
  }

  addCircleLayer(circle) {
    this.map.addSource('circleSource', {
      type: 'geojson',
      data: circle
    })

    this.map.addLayer({
      id: 'circleLayer',
      type: 'fill',
      source: 'circleSource',
      paint: {
        'fill-color': '#007cbf',
        'fill-opacity': 0.6
      }
    })
  }

  removeCircleLayer() {
    if (this.map.getLayer('circleLayer')) {
      this.map.removeLayer('circleLayer')
    }
    if (this.map.getSource('circleSource')) {
      this.map.removeSource('circleSource')
    }
  }

  disconnect() {
    this.map.remove()
  }
}
