import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";
import { GoogleCharts } from 'google-charts';
import _ from "lodash";
import checkPlaceOfInterest from "../helpers/checkPlaceOfInterest";

export default class extends Controller {
  static targets = [ "modal" ]

  static values = {
    workers: String
  }

  initialize() {
  }

  connect() {
    this.listenToTabSelection();

    this.drawChart();

    setTimeout(this.listenToContractorSelection.bind(this), 1000)
  }

  closeModal() {
    const controller = this;

    $(controller.element).find('.tasks').html(html);
    flash('notice', 'Tarefa salva com sucesso.');

    $('body').css({
      'overflow': 'auto',
      'padding-right': 'initial'
    });
    $('.modal-open').removeClass('modal-open');
    $('.modal-backdrop').remove();

    $('.spinner-container').hide();
  }

  listenToTabSelection() {
    const tabLinks = this.element.querySelectorAll('.nav-link');
    tabLinks.forEach(link => {
      link.addEventListener('shown.bs.tab', () => {
        // Display close tab btn
        const tabCloseButton = link.querySelector('.close-tab');
        if (tabCloseButton) {
          tabCloseButton.style.display = '';
        }
        this.drawChart();
      });

      link.addEventListener('hidden.bs.tab', () => {
        // Hide close tab btn
        const tabCloseButton = link.querySelector('.close-tab');
        if (tabCloseButton) {
          tabCloseButton.style.display = 'none';
        }
      });
    });
  }

  listenToContractorSelection() {
    $(this.element).find('.contractor-selector .selectized').toArray().forEach((element, index) => {
      element.selectize.on('change', (value) => this.showWorkersTable.bind(this)(element, value))
    })
  }

  showModal(e) {
    e.preventDefault();
    window.$(this.modalTarget).modal('show');
  }

  selectEnterprise() {
    const date = this.element.querySelector('#date').value;
    const enterpriseId = this.element.querySelector('#enterprise_id').value;
    checkPlaceOfInterest(enterpriseId, this.element, () => {
      Turbo.visit(`/site_diaries/new?enterprise_id=${enterpriseId}&date=${date}`, { action: 'replace' });
    });
  }

  drawChart() {
    _.each($(this.element).find('.tab-pane.active .workers .workers-subsection'), (workersTarget) => {

      const workers = $(workersTarget).find('.workers-data').data('source');
      const graphsTarget = $(workersTarget).find('.workers-graphs');
      const groupedByContractorId = _(workers)
        .groupBy('contractor_id')
        .mapValues(items => ({
          contractor_name: items[0].contractor.name,
          workers: items,
        }))
        .value();

      // Clean graphs target
      graphsTarget.html('');

      _.each(groupedByContractorId, (attrs, contractorId) => {
        // Append graph to graphs target
        graphsTarget.append(
          `<div id="chart-div-${contractorId}" style="margin-top: 20px;" class="col-md-${_.get(
            {1: 12, 2: 6}, _.size(groupedByContractorId), 4)
          }"></div>`
        )
        const target = graphsTarget.children().last()

        const counts = _.chain(attrs['workers'])
        .groupBy('job_role_id')
          .mapValues(items => {
            const count = _.sumBy(items, 'count');
            const jobRoleName = items[0].job_role.name;
            return { count, job_role_name: jobRoleName };
          })
          .value();

        GoogleCharts.load(() => {
          const data = new GoogleCharts.api.visualization.DataTable();

          // Adding columns
          data.addColumn("string", "Função");
          data.addColumn("number", "Contagem");

          // Adding rows
          _.each(counts, (attr, jobRoleId) => {
            data.addRow([attr['job_role_name'], attr['count']]);
          })

          // Set chart options
          const options = {
            title: attrs['contractor_name'],
            pieSliceText: 'value'
          };

          // Instantiate and draw the chart
          const chart = new google.visualization.PieChart(target[0]);
          chart.draw(data, options);
        }, { packages: ["corechart"] });
      })
    })
  }

  showWorkersTable(target, contractorId) {
    const diaryId = $(target).closest('.contractor-selector')[0].id.replace('contractor-selector-', '');
    const url = `/site_diaries/${diaryId}/edit_workers_count?contractor_id=${contractorId}`;
    fetch(url)
      .then(response => response.text())
      .then(data => {
        $(this.element).find('#workers-table-' + diaryId).html(data)
      });
  }

  workerCountUpdated(e) {
    const siteId = e.target.dataset['siteId'];
    const contractorId = $(this.element).find('#contractor-selector-' + siteId + ' #contractor_id').val();
    let workerCounts = [];

    $(this.element).find('#workers-table-' + siteId + ' tr input').each(function() {
      const jobRoleId = parseInt($(this).attr('data-job-role-id'));
      const countId = parseInt($(this).attr('data-worker-count-id'));
      const count = parseInt($(this).val());

      // Push the job role ID, contractor ID, and worker count to the workerCounts array
      workerCounts.push({
        job_role_id: jobRoleId,
        contractor_id: contractorId,
        count: count,
        id: countId
      });
    });

    let data = {
      site_diary: {
        id: siteId,
        worker_counts_attributes: workerCounts
      }
    };

    // Retrieve CSRF token from meta tag
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch('/site_diaries/' + siteId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken  // Include CSRF token in request headers
      },
      body: JSON.stringify(data)
    }).then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); // This returns a promise
    }).then(html => {
      document.open();
      document.write(html)
      document.close();
    }).catch(e => {
      console.log('There was a problem with the fetch operation: ' + e.message);
    });
  }

  filter (e) {
    Turbo.visit(`site_diaries?date=${
        $(this.element).find('#date')[0].value
    }`)
  }

  toggleWorkersSubsection(event) {
    var toggleContent = event.currentTarget.parentNode.querySelector('.section-toggle');
    var chevronIcon = event.currentTarget.querySelector('.chevron-icon');

    if (toggleContent.style.display === 'none') {
      toggleContent.style.display = 'block';
      chevronIcon.classList.remove('fa-chevron-right');
      chevronIcon.classList.add('fa-chevron-down');
    } else {
      toggleContent.style.display = 'none';
      chevronIcon.classList.remove('fa-chevron-down');
      chevronIcon.classList.add('fa-chevron-right');
    }
  }
}
