import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  deleteResourceItem(event) {
    event.preventDefault();
    const dataFieldId = $(event.currentTarget).data('resource-id');
    if (dataFieldId) {
      const destroyField = $(event.currentTarget).prev();
      destroyField[0].value = "true";
      // Hide the removed field from UI.
      $(event.currentTarget).closest('.resource').hide();
    } else {
      $(event.currentTarget).closest('.resource').remove();
    }
  }
}