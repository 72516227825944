import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="investments"
export default class extends Controller {
  connect() {
  }

  deleteFile(event) {
    event.preventDefault();
    const dataFieldId = $(event.currentTarget).data('investment-file-id');

    if (dataFieldId) {
      const destroyField = $(event.currentTarget).prev();
      destroyField[0].value = dataFieldId;
      $(event.currentTarget).closest('.card-file').hide();
    }
  }
}
