import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["method", "card", "fixedFund"];

  connect() {
    this.cardTarget.style.display = this.methodTarget.value == 'corporate_card' ? 'block' : 'none';
    this.fixedFundTarget.style.display = this.methodTarget.value == 'fixed_fund' ? 'block' : 'none';
  }

  checkMethod(event) {
    console.log(event.target.value);
    this.cardTarget.style.display = event.target.value == 'corporate_card' ? 'block' : 'none';
    this.fixedFundTarget.style.display = (event.target.value == 'fixed_fund') ? 'block' : 'none';
  }

  deleteFile(event) {
    event.preventDefault();
    const dataFieldId = $(event.currentTarget).data('payment-file-id');

    if (dataFieldId) {
      const destroyField = $(event.currentTarget).prev();
      destroyField[0].value = dataFieldId;
      $(event.currentTarget).closest('.card-file').hide();
    }
  }
}
