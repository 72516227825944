import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "content", "icon", "menu"]

  openNav() {
    this.menuTarget.style.width = "100%"
  }

  closeNav() {
    this.menuTarget.style.width = "0"
  }

  toggleActive(event) {
    this.itemTargets.forEach(item => item.classList.remove('active'));
    event.currentTarget.classList.add('active');

    this.closeAllDropdownsExceptCurrent();
  }

  toggleDropdown() {
    this.contentTarget.classList.toggle("hidden");
    this.iconTarget.classList.toggle("bi-chevron-down");
    this.iconTarget.classList.toggle("bi-chevron-up");
  }

  closeAllDropdownsExceptCurrent() {
    const dropdowns = document.querySelectorAll('.dropdown');
    dropdowns.forEach(dropdown => {
      if (dropdown != this.element) {
        const content = dropdown.querySelector('[data-navbar-target="content"]');
        const icon = dropdown.querySelector('[data-navbar-target="icon"]');
        content.classList.add('hidden');
        icon.classList.remove('bi-chevron-up');
        icon.classList.add('bi-chevron-down');
      }
    });
  }
}
