import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets =
    ["notification"]

  connect() {
  };

  disconnect() {
    const ids = this.notificationTargets.map(element => element.dataset.id)
    _.each(ids, (id) => {
      this.markAsRead(id);
    })
  }

  markAsRead(id) {
    fetch(`/notifications/${id}/mark_as_read`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .catch(error => {
      console.error("Error marking notifications as read:", error);
    });
  }

  deleteNotification(event) {
    event.preventDefault()
    const id = event.currentTarget.dataset.id

    fetch(`/notifications/${id}`, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then(response => {
      if (response.ok) {
        this.hideNotification(id)
      } else {
        console.error("Error deleting notification:", response.statusText)
      }
    })
    .catch(error => {
      console.error("Error deleting notification:", error)
    })
  }

  hideNotification(id) {
    const notification = document.querySelector(`[data-id='${id}']`)
    if (notification) {
      notification.classList.add('hidden')
    }
  }

}
